import React, { Component } from "react";
import './Footer.css'

class Footer extends Component {
  render() {
    return <div className="footer-text">© 2019 Cassidy Foust
    </div>;
  }
}

export default Footer;