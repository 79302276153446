import React, { Component } from 'react'
import * as emailjs from 'emailjs-com'
import {Form, FormGroup, Label, Input } from 'reactstrap';
import LetsConnect from './connect.png';
import './Email.css';

class ContactForm extends Component {
    state = {
        name: '',
        email: '',
        subject: '',
        message: '',
    }
    handleSubmit(e) {
        e.preventDefault()
        const { name, email, subject, message } = this.state
        let templateParams = {
            from_name: email,
            to_name: 'CassidyFoust',
            subject: subject,
            message_html: message,
        }
        emailjs.send(
            'gmail',
            'template_NqunmWib',
            templateParams,
            'user_cpm338CvVbcWtjgdIU4A0'
        )
        this.resetForm()
    }
    resetForm() {
        this.setState({
            name: '',
            email: '',
            subject: '',
            message: '',
        })
    }
    handleChange = (param, e) => {
        this.setState({ [param]: e.target.value })
    }

    render() {
        return (
          <>
            <img src={LetsConnect} height="75px"></img>
            <br></br>
            Want to get in touch? Email me at{" "}
            <a href="mailto:cassidyfoust@gmail.com" className="git-link">
              cassidyfoust@gmail.com
            </a>{" "}
            or fill out this form.
            <br></br>
            <Form onSubmit={this.handleSubmit.bind(this)}>
              <FormGroup controlId="formBasicEmail">
                <Label className="text-muted">Email address</Label>
                <Input
                  type="email"
                  name="email"
                  value={this.state.email}
                  className="text-primary"
                  onChange={this.handleChange.bind(this, "email")}
                  placeholder="Enter email"
                />
              </FormGroup>
              <FormGroup controlId="formBasicName">
                <Label className="text-muted">Name</Label>
                <Input
                  type="text"
                  name="name"
                  value={this.state.name}
                  className="text-primary"
                  onChange={this.handleChange.bind(this, "name")}
                  placeholder="Name"
                />
              </FormGroup>
              <FormGroup controlId="formBasicSubject">
                <Label className="text-muted">Subject</Label>
                <Input
                  type="text"
                  name="subject"
                  className="text-primary"
                  value={this.state.subject}
                  onChange={this.handleChange.bind(this, "subject")}
                  placeholder="Subject"
                />
              </FormGroup>
              <FormGroup controlId="formBasicMessage">
                <Label className="text-muted">Message</Label>
                <Input
                  type="textarea"
                  name="message"
                  className="text-primary"
                  value={this.state.message}
                  onChange={this.handleChange.bind(this, "message")}
                />
              </FormGroup>
              <button id="submit-email" type="submit"></button>
            </Form>
          </>
        );
    }
}
export default ContactForm